





























import BaseLayout from "@/components/BaseLayout.vue"
import Branch from "@/models/Branch"
import { Component } from "vue-property-decorator"

@Component({
  components: { BaseLayout },
})
export default class BranchView extends BaseLayout {
  private btnSaveLoading = false
  private branch: Branch = {
    name: "",
    organisation: "",
    email: "",
    phoneNo: "",
    faxNo: "",
    address: "",
  }

  private rules = {
    name: [(v: string) => !!v || "Name is required"],
    organisation: [(v: string) => !!v || "Organisation is required"],
    email: [
      (v: string) => !!v || "Email is required",
      (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phoneNo: [(v: string) => !!v || "Phone No is required"],
    faxNo: [(v: string) => !!v || "Fax No is required"],
    address: [(v: string) => !!v || "Address is required"],
  }

}
