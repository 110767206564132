var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{attrs:{"loadingScreen":_vm.loading,"snackbarMsg":_vm.snackbarMessage}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"md":"6"}},[_c('v-select',{attrs:{"items":_vm.branches,"outlined":"","dense":"","clearable":"","label":"Branch","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name + ", " + item.org.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name + ", " + item.org.name))])]}}]),model:{value:(_vm.selectedBranch),callback:function ($$v) {_vm.selectedBranch=$$v},expression:"selectedBranch"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"md":"6"}},[_c('v-select',{attrs:{"items":_vm.requestors,"outlined":"","dense":"","clearable":"","label":"Name","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedRequestor),callback:function ($$v) {_vm.selectedRequestor=$$v},expression:"selectedRequestor"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":_vm.onBranchClick}},[_vm._v("Add")]),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","color":"error"},on:{"click":_vm.onBranchEditClick}},[_vm._v("Edit")])],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":_vm.onRequestorClick}},[_vm._v("Add")])],1)],1),(_vm.showBranch)?_c('BranchView',{on:{"onError":_vm.onError,"onSuccess":_vm.onSuccess}}):_vm._e(),(_vm.showBranchEdit)?_c('BranchEditView',{on:{"onError":_vm.onError,"onSuccess":_vm.onSuccess}}):_vm._e(),(_vm.showRequestor)?_c('RequestorView',{on:{"onError":_vm.onError,"onSuccess":_vm.onSuccess}}):_vm._e(),_c('PropertyView',{attrs:{"states":_vm.states,"selectedBranch":_vm.selectedBranch,"selectedRequestor":_vm.selectedRequestor},on:{"onError":_vm.onError,"onSuccess":_vm.onSuccess}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }