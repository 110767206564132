import Branch from "@/models/Branch"
import Page from '@/models/Page'
import { RequestorBranch } from '@/models/RequestorBranch'
import Vue from "vue"

export default class RestRequestorBranch {
  public list(pageNo: number) {
    return Vue.$axios.get<Page<RequestorBranch>>(`requestorBranch/${pageNo}`)
  }

  public detail(id: string) {
    return Vue.$axios.get<Branch>("/branch/" + id)
  }

  public create(branch: Branch) {
    return Vue.$axios.post("/branch/create", branch)
  }

  public update(branch: Partial<Branch>) {
    return Vue.$axios.post("/branch/update", branch)
  }
}
