import Branch from "@/models/Branch"
import Vue from "vue"

export default class RestBranch {
  public list() {
    return Vue.$axios.get<Branch[]>("branch")
  }

  public detail(id: string) {
    return Vue.$axios.get<Branch>("/branch/" + id)
  }

  public create(branch: Branch) {
    return Vue.$axios.post("/branch/create", branch)
  }

  public update(branch: Partial<Branch>) {
    return Vue.$axios.post("/branch/update", branch)
  }
}
