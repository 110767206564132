


















































import BaseLayout from "@/components/BaseLayout.vue"
import { Component } from "vue-property-decorator"
import RestRequestor from "@/rest/RestRequestor"
import Requestor from "@/models/Requestor"

@Component({
  components: { BaseLayout },
})
export default class RequestorView extends BaseLayout {
  private btnSaveLoading = false
  private requestor: Requestor = {
    name: "",
    id: -1,
    code: "",
    slug: "",
    branchId: -1,
    userId: -1,
    contact: "",
    shortName: "",
    createdBy: "",
    modifiedBy: "",
  }

  private rules = {
    name: [(v: string) => !!v || "Name is required"],
    email: [
      (v: string) => !!v || "Email is required",
      (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phoneNo: [(v: string) => !!v || "Phone No is required"],
  }

  async onBtnSave() {
    if (!(this.$refs.form as HTMLFormElement).validate()) return
    try {
      this.btnSaveLoading = true
      await new RestRequestor().create(this.requestor)
      this.btnSaveLoading = false
      this.$emit("onSuccess", "Requestor Created Successfully")
    } catch (e) {
      this.btnSaveLoading = false
      this.$emit("onError", e)
    }
  }
}
