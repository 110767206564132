import Branch from "@/models/Branch"
import PropertyInfo from '@/models/PropertyInfo'
import PropertyType from '@/models/PropertyType'
import Requestor from '@/models/Requestor'
import Vue from "vue"

export default class RestProperty {
  public list() {
    return Vue.$axios.get<PropertyInfo[]>("property")
  }

  public detail(id: string) {
    return Vue.$axios.get<PropertyInfo>("/property/" + id)
  }
  
  public type() {
    return Vue.$axios.get<PropertyType[]>("/property/type")
  }

  public create(propertyInfo: PropertyInfo) {
    return Vue.$axios.post("/property/create", {
      unitNumber: propertyInfo.unitNumber,
      buildingName: propertyInfo.buildingName,
      propertyType: propertyInfo.propertyType,
      streets: propertyInfo.streets.toString(),
      landArea: propertyInfo.landArea,
      buildUpArea: propertyInfo.buildingName,
      postCode: propertyInfo.postCode,
      city: propertyInfo.city,
      state: propertyInfo.state,
    })
  }

  public update(propertyInfo: Partial<PropertyInfo>) {
    return Vue.$axios.post("/property/update", propertyInfo)
  }
}
