import Branch from "@/models/Branch"
import Page from "@/models/Page"
import Requestor from "@/models/Requestor"
import Vue from "vue"

export default class RestRequestor {
  public all() {
    return Vue.$axios.get<Requestor[]>("all")
  }

  public list() {
    return Vue.$axios.get<Page<Requestor>>("requestor")
  }

  public detail(id: string) {
    return Vue.$axios.get<Requestor>("/requestor/" + id)
  }

  public create(branch: Requestor) {
    return Vue.$axios.post("/requestor/create", branch)
  }

  public update(branch: Partial<Requestor>) {
    return Vue.$axios.post("/requestor/update", branch)
  }
}
