














































































































































import BaseLayout from "@/components/BaseLayout.vue"
import { Component, Prop } from "vue-property-decorator"
import RestProperty from "@/rest/RestProperty"
import PropertyInfo from "@/models/PropertyInfo"
import State from "@/models/State"
import PropertyType from "@/models/PropertyType"
import { RequestorBranch } from "@/models/RequestorBranch"
import Requestor from "@/models/Requestor"
import RestValuationRequest from "@/rest/RestValuationRequest"

@Component({
  components: { BaseLayout },
})
export default class PropertyView extends BaseLayout {
  @Prop() readonly states!: State
  @Prop() readonly selectedBranch!: RequestorBranch
  @Prop() readonly selectedRequestor!: Requestor
  private propertyInfo: PropertyInfo = {
    unitNumber: "",
    buildingName: "",
    propertyType: {},
    streets: ["", ""],
    landArea: 0,
    buildUpArea: 0,
    postCode: "",
    city: "",
    state: {},
  }

  private propertyTypes: PropertyType[] = []
  private bntSaveLoading = false
  // private selectedState: Partial<State> = {}

  private rules = {
    unitNumber: [(v: string) => !!v || "Unit Number is required"],
    buildingName: [(v: string) => !!v || "Building Name is required"],
    propertyType: [(v: string) => !!v || "Property Type is required"],
    street: [(v: string) => !!v || "Street is required"],
    landArea: [(v: string) => !!v || "Land Area is required"],
    buildUpArea: [(v: string) => !!v || "Build Up Area is required"],
    postCode: [(v: string) => !!v || "Postcode is required"],
    city: [(v: string) => !!v || "city is required"],
    state: [(v: string) => !!v || "state is required"],
  }

  mounted() {
    this.loadData()
  }

  async onSaveClick() {
    if (!(this.$refs.form as HTMLFormElement).validate()) return
    this.bntSaveLoading = true
    try {
      await new RestValuationRequest().create({
        requestorBranchSelectId: this.selectedBranch.id,
        requestorId: this.selectedRequestor.id,
        address: `${this.propertyInfo.unitNumber}, ${this.propertyInfo.buildingName}, ${this.propertyInfo.streets[0]}, ${this.propertyInfo.streets[1]}, ${this.propertyInfo.postCode} ${this.propertyInfo.city}`,
        area: this.propertyInfo.streets[1],
        state: this.propertyInfo.state.name,
        stateTypeId: this.propertyInfo.state.id,
        landAreaSqft: this.propertyInfo.landArea,
        builtupAreaSqft: this.propertyInfo.buildUpArea,
        typeOfProperty: this.propertyInfo.propertyType.id,
        branchId: this.selectedBranch.id,
        city: this.propertyInfo.city
      })
      this.$emit("onSuccess", "Property Created Successfully")
    } catch (e) {
      console.error(e)
      this.$emit("onError", e)
    } finally {
      this.bntSaveLoading = false
    }
  }

  async loadData() {
    const resp = await new RestProperty().type()
    this.propertyTypes = resp.data
  }
}
