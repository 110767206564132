















































































import BaseLayout from "@/components/BaseLayout.vue"
import BranchView from "./component/BranchView.vue"
import RequestorView from "./component/RequestorView.vue"
import PropertyView from "./component/PropertyView.vue"
import BranchEditView from "./component/BranchEditView.vue"
import { Component } from "vue-property-decorator"
import RestState from "@/rest/RestState"
import Requestor from "@/models/Requestor"
import State from "@/models/State"
import RestRequestor from "@/rest/RestRequestor"
import RestRequestorBranch from "@/rest/RestRequestorBranch"
import { RequestorBranch } from '@/models/RequestorBranch'

@Component({
  components: { BaseLayout, BranchView, RequestorView, PropertyView, BranchEditView},
})
export default class NewRequest extends BaseLayout {
  private showBranch = false
  private showRequestor = false
  private showBranchEdit = false
  private branches: RequestorBranch[] = []
  private requestors: Requestor[] = []
  private selectedBranch: Partial<RequestorBranch> = {}
  private selectedRequestor: Partial<Requestor> = {}
  private states: State[] = []

  mounted(): void {
    this.loadData()
  }

  onSuccess(msg: string) {
    this.toastSuccessMsg(msg)
  }

  onError(e: any) {
    this.toastExceptionMsg(e)
  }

  async loadData() {
    this.loading = true
    try {
      const resp = await new RestRequestorBranch().list(1)
      this.branches = resp.data.content
    } catch (e) {
      this.toastErrorMsg(e)
    }

    try {
      const resp = await new RestRequestor().list()
      this.requestors = resp.data.content
    } catch (e) {
      this.toastErrorMsg(e)
    }

    try {
      const resp = await new RestState().list()
      this.states = resp.data
    } catch (e) {
      this.toastErrorMsg(e)
    }

    this.loading = false
  }

  onBranchClick() {
    this.showBranch = !this.showBranch
    this.showRequestor = false
  }

  onRequestorClick() {
    this.showRequestor = !this.showRequestor
    this.showBranch = false
  }

  onBranchEditClick() {
    this.showBranchEdit = !this.showBranchEdit
    this.showBranch = false
    this.showRequestor = false
  }

}
